<template>
   <div id="inside">
     
    <div class="container">
      <div class="row">
        <div class="">
        </div>
        <div class="btn-group btn-group-sm">
            <a
              href="/"
              class="btn btn-link d-flex flex-column justify-content-center text-secondary"
              ><i class="fa fa-home fa-fw fa-lg"></i
            ></a>
            <router-link
              to="/dashboard/negociarDivida"
              ><a class="btn btn-link text-secondary">Negociar Dívidas</a>
            </router-link>
            <router-link
              to="#"
              ><a href="#" class="btn btn-link text-secondary" style="text-decoration: none; cursor: initial;">Negociar</a>
            </router-link>
        </div>
        <div class="col-md-12">
            <h1>Negociar</h1>
            <div class="row justify-content-between align-items-center col-12">
              <p class="text-muted">Confira os contratos e selecione a opção desejada.</p>
              <button v-if="!loadDeal" type="button" style="background-color: #23546d;" class="float-left btn text-white btn-lg btn-block col-2"
              @click="voltar()">Voltar</button>
            </div>
        </div>
        
      </div>
    </div>
    <div class="container">
        <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-12">
          <!-- v-for="(value, key, index) in contract"
          :key="`${key}-${index}`"> -->
          <div class="card col-12">
            <div class="card-body border-top-0">
              <div class="row col-12">
                  <h6 class="">Contratos selecionados</h6>
              </div>
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="table-responsive">
                    <table class="table table-sm" width="100%" cellspacing>
                      <thead class="">
                        <tr class="small">
                          <th>Contrato</th>
                          <th>Valor (R$)</th>
                          <th>Número de Parcelas Selecionadas</th>
                          <th>Valor atualizado</th>
                          <th width="10%"></th>
                        </tr>
                      </thead>
                      <tbody class="text-muted">
                        <tr v-for="(value, key, index) in contracts"
                          :key="`${key}-${index}`">
                          <td>{{ key }}</td>
                          <td>{{ value.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("-","") }}</td>
                          <td>{{ value.length }}</td>
                          <td>R$ {{ totalByContract[key] ? totalByContract[key].RETORNO.VALORTOTAL : "-" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <hr>
                </div>
              </div>
              <br>
              <div class="col-md-12" v-if="agreementClosed">
                <p class="small">Status do acordo: Aceito.</p> 
              </div>
              <div class="row" v-if="!loadDeal && !agreementClosed">
                  <div class="col-md-6 col-12">
                    <p class="" v-if="dateSelected === '' && changeDate">Selecione uma data para pagamento:</p>
                    <p class="" v-if="dateSelected != ''">Data selecionada: {{ dateSelected.toLocaleString().split(",")[0] }}</p>
                    <datepicker id="datepicker" class="form-control inrad" v-if="!loadDeal && changeDate" placeholder="dd/mm/aaaa" v-model="dateSelected"></datepicker> 
                    <!-- <input type="date" v-if="!loadDeal && !isUpdated" placeholder="dd/mm/aaaa" v-model="dateSelected">  -->
                    <!-- <a  class="btn btn-secondary mr-1 mb-1 mx-auto py-2 px-4 text-white" @click="resumoAcordo()"> Confirmar renegociação <i class="fa fa-fw fa-chevron-right"></i></a> -->
                    <button v-if="dateSelected != '' && !changeDate" type="button" style="background-color: #23546d;" class="float-left btn text-white btn-lg btn-block col-5"
                    @click="refresh()">Alterar data de pagamento</button>
                  </div>
                  <div class="col-md-6 col-12">
                    <button v-if="!loadDeal && dateSelected != '' && !isUpdated" type="button" style="background-color: #45c7a2;" class="float-right btn text-white btn-lg btn-block col-5"
                    @click="resumoAcordo()">{{ "Negociar à vista" }}</button>
                    <button v-if="isUpdated" type="button" style="background-color: #45c7a2;" class="float-right btn text-white btn-lg btn-block col-5"
                    @click="negociarAcordos()">Aceitar negociação</button>
                  </div>
                  <ProgressLoading v-bind:loading="loadDeal" />
                </div>
                
                <div class="container" v-if="loadDeal">
                  <ProgressLoading v-bind:loading="loadDeal" />
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData } from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";

export default {
    name: "HistoricoPagamentos",
     components: {
       ProgressLoading,
       Datepicker
    },
    data () {
        return {
            monthNames : ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
              "Jul", "Ago", "Set", "Out", "Nov", "Dez"
            ],
            loadDeal: false,
            dateSelected: '',
            validatorValue:'',
            eventCount:0,
            showRemoveContract:false,
            negociacao: null,
            isUpdated: false,
            contractsShowed:[],
            totalByContract: {},
            arrayToCall: [],
            contract :null,
            selectDeal:null,
            digitalLine:null,
            contractFull: null,
            allContracts : null,
            checkSelectAll: false,
            lastFilterValue:0,
            checkContract: false,
            expiredDate: new Date().toISOString().slice(0,10),
            links: [],
            changeDate: true,
            agreementClosed: false
        }
    },
    methods: {
        formatValue,
        formatData,

        ...mapActions('minhasDividas', 
          [
            'ActionDividaNegociar',
            'ActionGetOffersTribanco',
            'ActionSetAgreement'
          ]
        ),

        deactivated() {
            this.divida = null;
            this.negociacao = null;
            this.selectDeal = null;
            this.digitalLine = null;
        },
        voltar() {
             this.$router.push({ name: 'negociarDivida' });
        },
        showDigitalLine(){
          this.digitalLine = this.selectDeal.linhaDigitavel;
        },

        selectAll() {
              var marcar = document.getElementById('all').checked;
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = marcar;
              }
        },

        select() {
          var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
          var all = document.getElementById('all');
          if(lista.length <= 2) {
            all.checked = false;
          }
        },

        clear() {
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = false;
              }
        },

        calcDay(dateStr,isDate){
          const date = new Date(dateStr);
          if(isDate)
            return date;

          return date.getDate().toString().padStart(2, "0") + '/' + this.monthNames[date.getMonth()];
        },

        async negociarAcordos() {
          this.loadDeal = !this.loadDeal
          try {
            let reqAgreement = { ...this.arrayToCall[0], installments: [] }
  
            for (const req of this.arrayToCall) {
              req.installments.map((installment) => reqAgreement.installments.push(installment))
            }
  
            this.responseFromBoleto = await this.ActionSetAgreement(reqAgreement);
            
            localStorage.removeItem('contracts');
            localStorage.removeItem('contracts');
            localStorage.removeItem('contractsToUpdate');
            
            this.loadDeal = !this.loadDeal
            this.getBoleto()
          } catch {
            this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                blockClass: "custom-block-class",
                message: "Falha ao fechar o acordo!",
              });
            this.loadDeal = !this.loadDeal 
            return;
            
          }
        },

        getBoleto() {
          this.$router.push({
                name: "acordoFechado",
                params: { responseFromAgreement: this.responseFromBoleto },
          });
        },

        refresh() {
          this.changeDate = this.changeDate ? false : true;
          this.isUpdated = false;
          this.totalByContract = {};
        },


        async resumoAcordo() {

          this.loadDeal = true;
          this.changeDate = false;
          this.isUpdated = false;

          if (this.dateSelected - new Date() < 0) {

            this.flashMessage.show({
              timeout: 500,
              status: "error",
              blockClass: 'custom-block-class',
              title: "DATA INVÁLIDA",
              message: `O data selecionada deve ser maior do que a de hoje.!`,
            });
            this.loadDeal = false;
            return;
          }

          try {
            const allContracts = JSON.parse(localStorage.getItem('contracts'))
            this.arrayToCall = []
            for(const contract in this.contracts) {
              allContracts.map((value) => {
                if (value.contrato === contract) {
                  let thisContract = { 
                    document: value.regis,
                    credor: value.credor,
                    company: value.company, 
                    installments: [],
                    dueDate: this.dateSelected.toJSON().split("T")[0] 
                  }
                  value.parcelas.map((installment) => {
                    if (JSON.stringify(this.contracts[contract]).includes(JSON.stringify(installment))) {
                      thisContract.installments.push({
                        contract: value.contrato,
                        product: value.produto,
                        dueInstallmentDate: installment.vencimento
                      })
                    }
                  })
                  this.arrayToCall.push(thisContract)
                }
              })
            }
  
            for (const req of this.arrayToCall) {
              this.totalByContract[req.installments[0].contract] = await this.ActionGetOffersTribanco(req)
            }

            await this.delay(10000);
            this.isUpdated = !this.isUpdated;
            this.loadDeal = false;
          } catch {
            this.loadDeal = false;
          }

        },

        delay(time) {
          return new Promise(resolve => setTimeout(resolve, time));
        },

        async checkValueChange(event,item){
            this.validatorValue = '';
            if(item.Entrada < item.ValorEntradaMinimo && item.Entrada > 0){
              this.validatorValue = 'valor de entrada menor que o permitido';
              return;
            }

            if(item.Entrada > item.ValorEntradaMaximo){
              this.validatorValue = 'valor de entrada maior que o permitido';
              return;
            }
            
            if(this.lastFilterValue != item.Entrada){
              let selected = this.parcelamentSelected;
              selected.EntryValue = item.Entrada;
              this.ActionSetParcelamentSelected(selected);
              /*this.parcelamentSelected = item;*/
              this.lastFilterValue = item.Entrada;
              await this.delay(700);           
              await this.refreshValues();
            }           

        },

        changeExpireDate(filterDate){
          //this.expiredDate = filterDate.toISOString().slice(0,10);
          let selected = this.parcelamentSelected;
          selected.ExpiredDate = filterDate.toISOString().slice(0,10);

          this.ActionSetParcelamentSelected(selected);

          this.refreshValues();
        },

        isChecked(currentDate){
            var date = new Date(this.parcelamentSelected.ExpiredDate);

            if(date.toISOString().slice(0,10) == currentDate.toISOString().slice(0,10))
              return true;
            return false;
        },

        async removeContract(number){
          let contracts =  this.contractNegotiable;
          contracts[contracts.findIndex(v => v.NumeroContrato === number)].selected = false;

          this.ActionSetContractsNegotiable(contracts);
          await this.refreshValues();
        },

        async refreshValues(){
          var contracts = [];

          this.contractNegotiable.filter((f)=> f.selected).forEach((ctr) =>{
            let parcelas = ctr.Parcelas.filter((filter) => filter.selected == true).map((i)=> { return i.NumeroParcela});

            if(ctr.Parcelas.filter((filter) => filter.selected == true).length == ctr.Parcelas.length)
              parcelas = []; 

            contracts.push({
              "NumeroContrato": ctr.NumeroContrato,
              "Parcelas": parcelas
            });
          });
        
          this.loadDeal = true;
          if(this.parcelamentSelected == null)
            this.ActionSetParcelamentSelected({'ExpiredDate':new Date().toISOString().slice(0,10),'NumeroParcela':5 });


          var payload = {
            "DataVencimento":this.parcelamentSelected.ExpiredDate,
            "TipoNegociacao": this.contractNegotiable.filter((f)=> f.selected)[0].TipoNegociacao,
            "Contratos": contracts,
            "Entrada": 0,
          };

          if(this.parcelamentSelected != null && this.parcelamentSelected.EntryValue > 0)
            payload['Entrada'] = this.parcelamentSelected.EntryValue;

          var result =  await this.ActionGetContractsNegotiableOptions(payload);
          
          this.loadDeal = false;
          if (result.status == 500){
            this.flashMessage.show({
                        timeout:300,
                        status: 'error',
                        position:'top', 
                        blockClass: 'custom-block-class',
                        title: 'Erro', 
                        message: result.data.message});
          }else{
            this.showRemoveContract = contracts.length>1;
            this.contractsShowed = this.contractNegotiableOptions.FormasPagamento;
          }
        }           
    },
    async created() {
       
        this.user.Menus.forEach((menuId) => {
          switch (parseInt(menuId)) {
            case 2:
              this.links.push({
                icon: "",
                text: "Contratos Negociáveis",
                route: "/dashboard/minhas-dividas",
              });
              break;
            case 1:
              this.links.push({
                icon: "",
                text: "Boletos Ativos",
                route: "meusBoletos",
              });
              break;
            case 3:
              this.links.push({
                icon: "",
                text: "Acordos",
                route: "/dashboard/meus-acordos",
              });
              break;
          }
        });
        this.loadDeal = true;

        // this.parcelamentSelected = null;

        // this.ActionSetParcelamentSelected({
        //   'EntryValue':0,
        //   'ExpiredDate':new Date().toISOString().slice(0,10),
        //   'ExpiredDateParc':new Date().toISOString().slice(0,10),
        //   'NumeroParcela': this.contractsShowed.filter(f=> f.EntradaFlexivelPermitida).length > 0?2:1
        // });//SELECIONA A 1 OPCAO DO PARCELADOexDate
        
        // await this.refreshValues();
       
        this.contracts = this.$route.params.contracts;
        this.allContracts = this.$route.params.allContracts;

        // console.log('contracts: ' + JSON.stringify(this.$route.params.contracts));     
        // console.log('checkedParcels: ' + JSON.stringify(this.$route.params.checkedParcels));     

        this.loadDeal = false;
    },
    computed: {
      ...mapState("auth", ["user"]),
      ...mapState("minhasDividas", ["contractNegotiable","agreementResume","contractNegotiableOptions","parcelamentSelected"]),
      isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    }
};
</script>
<style scoped>

.vdp-datepicker {
  max-width: 25%;
  border: 2px;
}


</style>
